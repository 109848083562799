import { sendGAEvent } from '@noths/polaris-client-google-analytics';

import type { TrackingContext } from 'src/types/TrackingContext';

export const trackLinkClick = async (e: React.MouseEvent, trackingContext: TrackingContext) => {
  e.preventDefault();
  const { href, target, textContent } = e.target as HTMLAnchorElement;
  const { trackingContentType, trackingPosition, trackingTitle } = trackingContext;

  await sendGAEvent({
    event: 'custom_event',
    event_action: 'Link click',
    event_category: `${trackingPosition} | ${trackingContentType}`,
    event_label: `${trackingPosition} | ${trackingTitle} | ${textContent}`.toLowerCase(),
    event_name: 'select_content',
    event_details: 'link click',
    content_position: `${trackingPosition}`,
    content_type: `${trackingContentType}|${trackingTitle}`.toLowerCase(),
    destination_URL: href,
    item_id: `${textContent}`.toLowerCase(),
  });
  window.open(href, target);
};
