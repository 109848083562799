/* istanbul ignore file */
import { theme } from '@noths/polaris-client-ribbons-base';
import { stack } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing } = theme;

export const wrapper = css`
  margin: ${stack(spacing.xxxl)};
`;
