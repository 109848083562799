import React from 'react';
import type {
  CopyBlockAlignment,
  CopyBlockProps,
} from '@noths/polaris-client-ribbons-design-system';
import { CopyBlock } from '@noths/polaris-client-ribbons-design-system';
import parse from 'html-react-parser';

import { SectionContainer } from 'src/components/molecules/SectionContainer/SectionContainer';
import { trackImpression } from 'src/tracking/common/trackImpression';
import { trackLinkClick } from 'src/tracking/components/copyBlock';
import type { TrackingContext } from 'src/types/TrackingContext';
import * as styles from './CopyBlockSection.style';

export interface CopyBlockSectionProps extends CopyBlockProps {
  children: string;
  trackingPosition?: number;
  trackingTitle: string;
}

export const CopyBlockSection = ({
  alignment,
  children,
  trackingPosition,
  trackingTitle,
}: CopyBlockSectionProps) => {
  const trackingContext: TrackingContext = {
    trackingPosition,
    trackingTitle,
    trackingContentType: 'Copy block',
  };

  return (
    <SectionContainer columnsL={8} maxWidth="full" wrapperStyles={styles.wrapper}>
      <CopyBlock
        alignment={alignment}
        onLinkClick={(e: React.MouseEvent) => trackLinkClick(e, trackingContext)}
        onVisible={() => trackImpression(trackingContext)}
      >
        {parse(children)}
      </CopyBlock>
    </SectionContainer>
  );
};

export type { CopyBlockProps, CopyBlockAlignment };
