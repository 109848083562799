import { sendGAEvent } from '@noths/polaris-client-google-analytics';

import type { TrackingContext } from 'src/types/TrackingContext';

export const trackImpression = ({
  trackingContentType,
  trackingPosition,
  trackingTitle,
}: TrackingContext) => {
  sendGAEvent({
    event: 'custom_event',
    event_action: 'Impression',
    event_category: `${trackingPosition} | ${trackingContentType}`,
    event_label: `${trackingPosition} | ${trackingTitle}`.toLowerCase(),
    event_name: 'view_content',
    content_position: `${trackingPosition}`,
    content_type: `${trackingContentType}|${trackingTitle}`.toLowerCase(),
  });
};
